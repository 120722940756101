import Address from "../components/core/forms/contact/Address";
import { CONTACT } from "../components/core/nav/NavigationItems";
import { Container } from "react-bootstrap";
import HomeShape from "../components/core/ux/HomeShape";
import Layout from "../components/core/Layout";
import React from "react";

const ContactPage = () => (
  <Layout page={CONTACT}>
    <HomeShape />
    <Container className="mb-5">
      <h2 className="text-uppercase h-muted">new.space</h2>
      <h1 className="text-uppercase h bolder">{CONTACT}</h1>
      <h3 className="headline">
        <strong>new.space</strong>
      </h3>
      <Address />
      <br />
      <address>
        <h3 className="headline">Pressebereich</h3>
        <br />
        Claudia Stehr
        <br />
        Telefon: <a href="tel:0049561739888417">+49 561 739 888 417</a>
        <br />
        E-Mail: <a href="mailto:presse@newspace-ag.de">presse@newspace-ag.de</a>
      </address>
      <br />
      <address>
        <h3 className="headline">Ankauf/Projekte</h3>
        <br />
        E-Mail:{" "}
        <a href="mailto:projekte@newspace-ag.de">projekte@newspace-ag.de</a>
      </address>
    </Container>
  </Layout>
);

export default ContactPage;
